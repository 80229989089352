

<div *ngIf="loadedData" class="nav-container">

  <mat-toolbar color="primary" *ngIf="!waitStatus" class="mat-elevation-z1">
      <mat-toolbar-row>

            <div class="nav-app-name">
                <div class="activity-session-title">{{activitySession.Description}}</div>
            </div>
                    
            <div *ngIf="activity.Status == 'VOTE'" class="vote-toolbar-container">Votes Left: {{voteCount}}</div>
 
        <div class="action-item">
            <button class="action-button" mat-raised-button color="accent" (click)="SubmitResponse()">Submit</button>
        </div>
       
     </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container class="nav-sidenav-container">
    <mat-sidenav-content>  
        <div *ngIf="loadedData"  class="content-top-spacer">
            <div *ngIf="!waitStatus && activity.Template.TemplateType=='OneBoxVoting' && activity.Status=='VOTE'">
               <app-onebox-main [activity]="activity" [participantMode]="true"></app-onebox-main>
             </div>
             
            <div *ngIf="!waitStatus && activity.Template.TemplateType=='OneListVoting' && (activity.Status=='VOTE' || activity.Status == 'CAPTURE')">
               <app-onelist-main [activity]="activity" [participantMode]=true></app-onelist-main>
             </div>    
       </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
 

 
 </div>


   