import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import {  Observable, from  } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { Auth } from 'aws-amplify';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (!req.headers.has("Content-Type")) {
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json")
      });
    }
    
    return from(Auth.currentSession())
            .pipe(
                switchMap((auth: any) => { // switchMap() is used instead of map().
                    //console.log("interceptor auth : " + JSON.stringify(auth));
                    let jwt = auth.idToken.jwtToken;
                    let with_auth_request = req.clone({
                        setHeaders: {
                            Authorization: `${jwt}`
                        }
                    });
                    //console.log("Cloned",with_auth_request);
                    return next.handle(with_auth_request);
                }),
                catchError((err) => {
                    console.log("Error in token interceptor....... ", err);
                    return next.handle(req);
                })
            );
            

  }
  
private getTokenString()
{
  return from(Auth.currentSession()).pipe(
    switchMap(session => session.getAccessToken().getJwtToken()
  ));
}

private getToken(): Observable<string>
{
  return from(Auth.currentSession()).pipe(
    switchMap(session => from(session.getAccessToken().getJwtToken())
  ));
}

}