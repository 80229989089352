import { Component, OnInit } from '@angular/core';
import { DataService } from '../../core/services/data.service';


@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit {

  ngOnInit(): void {
    
  }

}
