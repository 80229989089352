/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_cognito_identity_pool_id": "us-west-1:150d0842-5ffd-4dc5-8279-2db003c5fded",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_oUB2S9SVL",
    "aws_user_pools_web_client_id": "3gu69vpneq0v6u5e0bf2h3odls",
    "oauth": {}
};


export default awsmobile;
