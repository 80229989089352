import { Component, OnInit } from '@angular/core';
import { DatastoreService } from '../../../core/services/datastore.service';
import { DataService } from '../../../core/services/data.service';
import { ActivityTemplate } from '../../../core/models/activitytemplate.model'
import { Router } from '@angular/router';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  activityTemplate : Array<ActivityTemplate>;
  description : string;
  
  constructor(private dataStoreService : DatastoreService, private dataService : DataService, private router : Router) { }

  ngOnInit(): void {
    
    this.dataService.GetActivityTemplates().subscribe((data:any)=>{
      //remove the skip template if this is not a new session selection
      if(this.dataStoreService.sessionData.newSession === false){
        let templateIndex = data.Items.findIndex(item=> item.TemplateID == '0');
        if(templateIndex >= 0){
          let item = data.Items[templateIndex];
          item.Description = 'Cancel activity template selection and go back to the retro board';
          item.Template = {
              TemplateID: 'X'
            };
          item.TemplateName = 'Back To Retro Board';
        }
      }
      this.activityTemplate = data.Items.sort((x,y)=> (x.SequenceNumber > y.SequenceNumber) ? 1: -1);
    });

  }
  
  ngAfterContentChecked(): void {
    this.setborder();
  }
  
  setborder() {
    let border = {}; 
    
    let cards = document.getElementsByClassName("card");

    for(let index = 0; index < cards.length; index++){
      let color = Math.floor(Math.random()*16777215).toString(16);
      let styleValue = 'border-left:5px solid #' + color;
      cards[index].setAttribute("style",styleValue);
      let avatarStyle = "color:#" + color ; 
      cards[index].getElementsByTagName("mat-icon")[0].setAttribute("style",avatarStyle);
    }
    

  }    
  
  GotoWorkshop($event, item){

    if(this.dataStoreService.sessionData.newSession === true){
      let defaultDescription = "Untitled Retrospective";
      this.dataService.CreateNewSession(defaultDescription).subscribe(data => {
        this.dataStoreService.sessionData.sessionCode = data.Code;

        if (item.TemplateID == '0' || item.TemplateID == 'X'){
          this.dataStoreService.sessionData.activity = null;
          this.dataStoreService.Save();
          this.router.navigate(['/','workspace','board']);
        } else {
          this.dataService.CreateNewActivity(this.dataStoreService.sessionData.sessionCode, item.TemplateID, item.TemplateName)
          .subscribe(data => {
             item.Description = this.description;
              this.dataStoreService.sessionData.activity = data.Item;
              this.dataStoreService.sessionData.activityTemplate = item;
              this.dataStoreService.Save();
              this.router.navigate(['/','workspace','board']);
          }, error => {
            console.log("Error adding new activity " + JSON.stringify(error));
          });
        }
        
        
      }, (error)=>{
        console.log("Error detected while creating a new session.");
        console.log(JSON.stringify(error));
      });
    }else {
        if (item.TemplateID == '0' || item.TemplateID == 'X'){
          this.dataStoreService.sessionData.activity = null;
          this.dataStoreService.Save();
          this.router.navigate(['/','workspace','board']);
        } else {
            this.dataService.CreateNewActivity(this.dataStoreService.sessionData.sessionCode, item.TemplateID, item.TemplateName)
              .subscribe(data => {
                 item.Description = this.description;
                  this.dataStoreService.sessionData.activity = data.Item;
                  this.dataStoreService.sessionData.activityTemplate = item;
                  this.dataStoreService.Save();
                  this.router.navigate(['/','workspace','board']);
              }, error => {
                console.log("Error adding new activity " + JSON.stringify(error));
              });
              
        }
    }
    
    
  
   
  }

}
