
export class ActivityTemplateDataItem  {
  UID : string;
  Description:string;
  Url:string;
  Tag:string;
  Vote:number;
  Selected:boolean;
  SequenceNumber:number;
  BackgroundColor:string;
  FontColor:string;
  FontSize:string;
  Group:ActivityTemplateDataItem[];
  constructor(){}
};

export class ActivityTemplateGroupDataItem  {
  UID : string;
  Description:string;
  Url:string;
  Tag:string;
  Vote:number;
  Selected:boolean;
  SequenceNumber:number;
  Group:ActivityTemplateDataItem[];
  constructor(){}
};

export class  ActivityTemplateData  {
    UID : string;
    Description:string;
    Cards: ActivityTemplateDataItem[];
    Hint:string;
   constructor(){}
};

export class VoteSettings{
    MaxVoteCount: number;
    Type:string;
    IconCount : number;
    InitialVoteCount:number;
    Label:string;
    SelectedVoteIcon:string;
    UnselectedVoteIcon:string;
    ExclusiveVote:boolean;
}

export class ActivityTemplate {
    Description : string;
    Lists : ActivityTemplateData[];
    GroupType :string;
    TemplateType:string;
    TemplateName:string;
    Category: string;
    TemplateID : string;
    SequenceNumber: number;
    VoteSettings: VoteSettings;
    Actions : string[];
    Active : boolean;
   constructor(){ }

};

export class Activity {
    ActivityID:string;
    Description:string;
    SessionCode:string;
    Status: string;
    Template : ActivityTemplate;
    RequestID:string;
    LastAction:string;

}

export class SessionData {
    cardInDragState:boolean;
    sessionCode : string;
    activity : Activity;
    activityTemplate : ActivityTemplate;
    newSession:boolean;
    participantSession:boolean;
    activitySession : ActivitySession;
    constructor(){}

}

export class ActivitySession {
    ActivitySessionID: string;
    SubscriberID : string;
    SessionCode : string;
    Description: string;
    startDate : string;
    EndDate : string;
    CreateDate: string;
    constructor(){};
}

export class ParticipantData{
    SessionCode:string;
    ParticipantID:string;
    constructor(sessionCode:string, participantID:string){
        this.SessionCode = sessionCode;
        this.ParticipantID = participantID;
    }
}

export class LocalData {
    SessionParticipantData : ParticipantData[];
    constructor(){
        this.SessionParticipantData = [];
    }
}

