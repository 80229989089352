

<div class="nav-container">

  <mat-toolbar color="primary" class="nav-toolbar mat-elevation-z1">
    <div class="nav-menu-container"><button class="nav-menu" style="vertical-align:middle;" mat-icon-button (click)="snav.toggle()"><mat-icon class="nav-appname-icon">offline_bolt</mat-icon></button></div>
    <div class="nav-app-name">
        <div *ngIf="loadedSessionData" id="activitySessionTitle" class="activity-session-title" (keydown)="onSessionDescriptionKeydown($event)" (blur)="updateSessionDescription()" text-editable [(ngModel)]="activitySession.Description">{{activitySession.Description}}</div>
        <!--<div class="activity-session-title" (keydown)="onActivityDescriptionKeydown($event)" (blur)="updateActivityDescription()" text-editable [(ngModel)]="activity.Description">{{activity.Description}}</div>-->
      
    </div>
    <div *ngIf="displayTimer" class="timer-display"  id="timer-display">
        <timer-watch [maxMinutes]="timerMaxValue" [isCountdown]="timerIsCountDown"></timer-watch>
        <button mat-icon-button matTooltip="Stop timer and get submitted results" id="stoptimer" (click)="CaptureResults();"><mat-icon style="vertical-align: middle -webkit-baseline-middle">stop</mat-icon></button> 
    </div>
    <div *ngIf="loadedData" class="nav-right-side-container">
        <div class="right-side-item">
            <mat-icon style="color:white; padding-right:5px;display: flex;align-items: center;">key</mat-icon>{{activitySession.SessionCode}}
        </div>
    </div>
   
  </mat-toolbar>

  <mat-sidenav-container class="nav-sidenav-container">
    <mat-sidenav class="mat-toolbar-container mat-elevation-z2" opened #snav [mode]="'side'" [opened]=true>
        <div *ngIf="loadedData" >
           
            <button *ngIf="activity.Template.Actions.includes('VOTE')" style="margin-top:10px;" mat-icon-button matTooltip="Vote on current activity items" id="vote" (click)="Vote();"><mat-icon>thumb_up_off_alt</mat-icon></button> 
         
            <button *ngIf="activity.Template.Actions.includes('CAPTURE')" mat-icon-button matTooltip="Capture ideas" id="capture" (click)="Capture();"><mat-icon>lightbulb</mat-icon></button> 
        
            <button mat-icon-button matTooltip="Start a new activity in the same session" (click)="NewActivity();"><mat-icon>local_activity</mat-icon></button> 
         
            <button mat-icon-button matTooltip="Share activity access info"  (click)="ShowSessionAccess();"><mat-icon>share</mat-icon></button> 
         
            <button mat-icon-button matTooltip="Join as a participant"  (click)="JoinAsParticipant();"><mat-icon>tab</mat-icon></button> 
       
            <!--<button mat-icon-button matTooltip="Enable design mode" (click)="SetDesignMode(true)"><mat-icon>design_services</mat-icon></button> -->
            <button *ngIf="!isFullScreen" mat-icon-button matTooltip="Fullscreen" (click)="openFullscreen();"><mat-icon>fullscreen</mat-icon></button> 
            <button *ngIf="isFullScreen" mat-icon-button matTooltip="Exist fullscreen" (click)="closeFullscreen();"><mat-icon>fullscreen_exit</mat-icon></button> 
         
            <button mat-icon-button matTooltip="Go to home page" [routerLink]="'/'"><mat-icon>home</mat-icon></button> 
         
            <button mat-icon-button matTooltip="Logout from session" (click)="Signout()"><mat-icon>logout</mat-icon></button>   
         
            <button mat-icon-button matTooltip="Version Info" (click)="About();"><mat-icon>info</mat-icon></button>  
         
        </div>
    </mat-sidenav>

    <mat-sidenav-content>  
        <div *ngIf="loadedData" class="content-top-spacer">
            <app-onebox-main *ngIf="activity !== null && activity.Template.TemplateType=='OneBoxVoting'" [activity]="activity" [participantMode]="false"></app-onebox-main>
            <app-onelist-main *ngIf="activity !== null && activity.Template.TemplateType=='OneListVoting'" [activity]="activity" [participantMode]="false"></app-onelist-main> 
       </div>
    </mat-sidenav-content>
  </mat-sidenav-container>


 </div>

