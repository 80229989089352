import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Activity,ActivityTemplateDataItem,ActivityTemplateData
} from '../../../../../core/models/activitytemplate.model';
import { DatastoreService } from '../../../../../core/services/datastore.service';
import { DataService } from '../../../../../core/services/data.service';
import { WorkspaceSharedService } from '../../../../../core/services/workspace.shared.service';

@Component({
  selector: 'app-onelist-main',
  templateUrl: './onelist-main.component.html',
  styleUrls: ['./onelist-main.component.scss'],
})
export class OnelistMainComponent implements OnInit {
  @Input() participantMode:boolean;
  @Input() activity :Activity;
  
  selectedUID: string = '';
  selectedItemDescription: string = '';
  originalItemDescription: string = '';

  selectedActivityDescription: string = '';
  originalActivityDescription: string = '';

  constructor(
    private dataService: DataService,
    private dataStoreService: DatastoreService,
    public workspaceSharedService : WorkspaceSharedService,
    private router:Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {

  }
  
  
  addNewList(){
    var newList = new ActivityTemplateData();
    newList.UID = Date.now().toString();
    newList.Description = '';
    newList.Hint = '';
    newList.Cards = [];
    this.activity.Template.Lists.push(newList);
    
    //set the new list description as active for input
    //we have to delay setting the focus to give the model
    //enough time to render 
    setTimeout(function(){
      let element = document.querySelector<HTMLInputElement>('[ng-reflect-name="' + newList.UID + '"]');
      var s = window.getSelection(),
          r = document.createRange();
      r.setStart(element, 0);
      r.setEnd(element, 0);
      s.removeAllRanges();
      s.addRange(r);     
      var addNewListButton = document.getElementById("addNewList");
      addNewListButton.scrollIntoView();
    },300);    
    
  }
  
  removeList(groupUID:string){
    console.log("removing ...." + groupUID);
    for(var i=0; i < this.activity.Template.Lists.length ; i++){
      if(this.activity.Template.Lists[i].UID == groupUID){
        this.activity.Template.Lists.splice(i,1);
        this.updateActivity();
        break;
      }
    }
  }
  
 updateActivity(){
     this.dataService.UpdateActivity(this.activity).subscribe(
    (data) => {
      this.dataStoreService.Save();
      console.log('updated activity');
      return;
    },
    (error) => {
      console.log('error updating activity : ' + JSON.stringify(error));
      return;
    }
  );  
 }
  
}
