import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './features/home/home.component';
import {AuthComponent} from './auth/auth.component';
import {WorkspaceComponent} from './features/workspace/workspace.component';
import {NewSessionComponent} from './features/workspace/new-session/new-session.component';
import { BoardComponent } from './features/workspace/board/board.component';
import { ActivityComponent } from './features/workspace/activity/activity.component';
import { SessionComponent } from './features/workspace/session/session.component';
import { AuthGuard } from './core/guards/auth.guard';
import { UnauthGuard } from './core/guards/unauth.guard';

const routes: Routes = [
  {path:'',component: HomeComponent, canActivate:[UnauthGuard]},
  {path:'auth', component: AuthComponent, canActivate: [UnauthGuard]},
  {path:'workspace', component: WorkspaceComponent, children: [
      {path:'newsession', component: NewSessionComponent, canActivate: [AuthGuard]},
      {path:'board', component: BoardComponent, canActivate: [AuthGuard]},
      {path:'session', component: SessionComponent, canActivate: [UnauthGuard],runGuardsAndResolvers: 'always'},
      {path:'activity', component: ActivityComponent, canActivate: [AuthGuard]},
      
      
    ]
  },
 

  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation:'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
