import { Component, OnInit, Inject } from '@angular/core';
import { DatastoreService } from '../../../core/services/datastore.service'
import { Router } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Activity } from 'src/app/core/models/activitytemplate.model';

@Component({
  selector: 'app-sessioninfo',
  templateUrl: './sessioninfo.component.html',
  styleUrls: ['./sessioninfo.component.scss']
})
export class SessioninfoComponent implements OnInit {

  sessionCode : string = '';
  sessionDescription : string = '';
  hostName : string = '';
  activity : Activity;
  
  constructor(public dialogRef:MatDialogRef<SessioninfoComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private dataStoreService: DatastoreService, private router : Router) { 
    
  }

  ngOnInit(): void {
    
    this.sessionCode = this.dataStoreService.sessionData.sessionCode;
    this.sessionDescription = this.dataStoreService.sessionData.activitySession.Description;
    this.hostName = window.location.hostname;
    this.activity = this.dataStoreService.sessionData.activity;
  }
  

  public Close(){
    this.dialogRef.close();
  }
  
  public CopyLink(elementId:string){
    var range = document.createRange();
    range.selectNode(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }
  
  SelectActivityTemplate(){
    this.dialogRef.close();
    this.router.navigate(['/','workspace','activity']);
  }

}
