import { Component, OnInit, OnDestroy, Input  } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'timer-watch',
  templateUrl: './timer-watch.component.html',
  styleUrls: ['./timer-watch.component.scss']
})
export class TimerWatchComponent implements OnInit {
  private subscription: Subscription;
  @Input() maxMinutes: number = 3;
  @Input() isCountdown: boolean = false;

  minutes:number = 0;
  seconds:number = 0;
  hours:number = 0;
  secondsRunningValue:number = 0;
  
  timerDisplay:string = "00:00";
  maxTimeReached:boolean = false;
  
  constructor() { 
    
  }


  private reset(){
    //console.log('resetting clock');
    this.minutes = 0;
    this.seconds = 0;
    this.hours = 0;
    this.secondsRunningValue = 0;
    
    this.maxTimeReached = false;
  }
  private CountDown(){
    
    if(this.secondsRunningValue <= 0 && this.maxMinutes > 0){
      this.maxTimeReached = true;
    }
    
    this.minutes = 0;
    this.hours = 0;
    this.seconds = 0;
    this.secondsRunningValue = this.secondsRunningValue - 1;
    
    let absValue = Math.abs(this.secondsRunningValue);


      this.minutes = Math.floor( absValue / 60);
      
      //get seconds remainder
      this.seconds = absValue - (this.minutes * 60);
      
      if(this.minutes >= 60){
        this.hours = Math.floor(this.minutes / 60);
        this.minutes = this.minutes - (this.hours * 60);
        
      
        this.timerDisplay = (this.secondsRunningValue < 0 ? "-" : "") + (this.hours > 0 ? (this.formatNumberDisplay(this.hours) + ":") : "") +  
                          this.formatNumberDisplay(this.minutes) + ":" +
                          this.formatNumberDisplay(this.seconds);        
      }

      if(this.minutes >= this.maxMinutes && this.maxMinutes > 0 && this.seconds % 2 == 0){
        this.maxTimeReached = true;
      }else {
        this.maxTimeReached = false;
      }
    
  }

  private CountUp(){

    this.minutes = 0;
    this.hours = 0;
    this.seconds = 0;
    this.secondsRunningValue = this.secondsRunningValue + 1;
    
    //calculate time elapsed based on seconds
    if(this.secondsRunningValue < 60){
      this.timerDisplay = this.formatNumberDisplay(this.minutes) + ":" + this.formatNumberDisplay(this.secondsRunningValue);
    }
    
    if(this.secondsRunningValue >= 60){
      this.minutes = Math.floor( this.secondsRunningValue / 60);
      
      //get seconds remainder
      this.seconds = this.secondsRunningValue - (this.minutes * 60);
      
      if(this.minutes >= 60){
        this.hours = Math.floor(this.minutes / 60);
        this.minutes = this.minutes - (this.hours * 60);
              
        this.timerDisplay = this.formatNumberDisplay(this.hours) + ":" +  
                          this.formatNumberDisplay(this.minutes) + ":" +
                          this.formatNumberDisplay(this.seconds);
        }else{
          this.timerDisplay = this.formatNumberDisplay(this.minutes) + ":" +
                            this.formatNumberDisplay(this.seconds);
        }

      }
    
    if(this.minutes >= this.maxMinutes && this.maxMinutes > 0 && this.seconds % 2 == 0){
      this.maxTimeReached = true;
    }else {
      this.maxTimeReached = false;
    }
    
  }
  
  private formatNumberDisplay(value:number){
    let absValue = Math.abs(value);
    if(absValue.toString().length == 1){
      return "0" + Math.abs(absValue).toString();
    }
    
    return Math.abs(absValue ).toString();
  }
  
  
  ngOnInit() {
    this.reset();
    if(this.isCountdown){
      this.secondsRunningValue = this.maxMinutes * 60;
      this.subscription = interval(1000)
           .subscribe(x => { this.CountDown(); });
    }else{
      this.subscription = interval(1000)
           .subscribe(x => { this.CountUp(); });
    }
    
  }
  
  ngAfterViewInit(){
    //console.log('afterviewinit');
  }
  
  ngAfterContentInit(){
    //console.log('aftercontentinit');
  }
  
   ngOnDestroy() {
     this.reset();
      this.subscription.unsubscribe();
   }

}
