import {Auth} from '@aws-amplify/auth';
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AuthService implements OnInit {
    
    ngOnInit(): void {
        console.log('im on iit');
        
    }
    
    constructor(){
      //  this.GetUserLoggedInStatus();
    };
    
    async isLoggedIn(){
        try {
                let user = await Auth.currentAuthenticatedUser();
                if(user != null)
                    return true;
                return false;
        }
        catch (err) {
            return false;
        }
    }

    
}