//angular app modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule , HTTP_INTERCEPTORS  } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ReactiveFormsModule } from '@angular/forms';



//angular material
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; 
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';

//components
import { OnelistMainComponent } from './features/shared/layout/onelist/onelist-main/onelist-main.component';
import { OnelistVoteComponent } from './features/shared/layout/onelist/onelist-vote/onelist-vote.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './features/home/home.component';
import { LandingSectionComponent } from './features/home/landing-section/landing-section.component';
import { AuthComponent } from './auth/auth.component';
import { WorkspaceComponent } from './features/workspace/workspace.component';
import { NewSessionComponent } from './features/workspace/new-session/new-session.component';
import { BoardComponent } from './features/workspace/board/board.component';
import { ActivityComponent } from './features/workspace/activity/activity.component';
import { SessionComponent } from './features/workspace/session/session.component';
import { OneboxMainComponent } from './features/shared/layout/onebox/onebox-main/onebox-main.component';
import { OneboxVoteComponent } from './features/shared/layout/onebox/onebox-vote/onebox-vote.component';
import { InfoDialogComponent } from './features/shared/info-dialog/info-dialog.component';
import { VoteDialogComponent } from './features/shared/components/vote-dialog/vote-dialog.component'
import { TimerWatchComponent } from './features/shared/timer-watch/timer-watch.component';
import { VoteRatingComponent } from './features/shared/vote-rating/vote-rating.component';
import { WaitStatusComponent } from './features/shared/wait-status/wait-status.component';
import {SessioninfoComponent} from './features/shared/sessioninfo/sessioninfo.component';
import { NewActivityDialogComponent } from './features/shared/new-activity-dialog/new-activity-dialog.component';
import {PrivacyDialogComponent } from './features/shared/components/privacydialog/privacydialog.component';

//services and interceptors
import { DatastoreService } from './core/services/datastore.service';
import { TokenInterceptor } from './core/interceptors/tokenInterceptor';
import { SpinnerInterceptor } from './core/interceptors/spinnerInterceptor'

//directives
import {TextEditableComponent} from './features/shared/directive/text-editable.directive';




//aws modules
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { ProgressSpinnerComponent } from './features/shared/components/progress-spinner/progress-spinner.component';
import { ListComponent } from './features/shared/components/list/list.component';
import { CardComponent } from './features/shared/components/card/card.component';
import { OneBoxCardComponent } from './features/shared/components/one-box-card/one-box-card.component'
import { OneBoxComponent } from './features/shared/components/one-box/one-box.component';
import { CardPropertiesComponent } from './features/shared/components/card-properties/card-properties.component';

Amplify.configure(awsconfig);



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LandingSectionComponent,
    AuthComponent,
    WorkspaceComponent,
    NewSessionComponent,
    BoardComponent,
    ActivityComponent,
    SessionComponent,
    OneboxMainComponent,
    OneboxVoteComponent,
    InfoDialogComponent,
    VoteDialogComponent,
    TimerWatchComponent,
    VoteRatingComponent,
    WaitStatusComponent,
    NewActivityDialogComponent,
    SessioninfoComponent,
    OnelistMainComponent,
    OnelistVoteComponent,
    TextEditableComponent,
    ProgressSpinnerComponent,
    ListComponent,
    CardComponent,
    OneBoxComponent,
    OneBoxCardComponent,
    PrivacyDialogComponent,
    CardPropertiesComponent
  ],
  imports: [
    AmplifyUIAngularModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatCardModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatMenuModule,
    MatSnackBarModule
  ],
  providers: [
    { 
      provide : HTTP_INTERCEPTORS,
      useClass :TokenInterceptor,
      multi: true
    
    },
    { 
      provide : HTTP_INTERCEPTORS,
      useClass :SpinnerInterceptor,
      multi: true
    
    }, DatastoreService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
