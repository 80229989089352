import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstants} from '../constants/urlConstants';
import { Observable } from 'rxjs';
import {DatastoreService} from './datastore.service';
import { Activity, ActivitySession } from '../models/activitytemplate.model' ;
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient, private datastoreService : DatastoreService){
    
  }
  
  CreateNewActivity(sessionCode:string, templateID : string, description: string):Observable<any>{
    var endPointUrl = UrlConstants.CreateNewActivity;
    
    let data = {
      description : description,
      sessionCode: sessionCode,
      templateID : templateID
    }

    return this.http.post<any>(endPointUrl, data);
  }
  
  UpdateActivity(activity:Activity){
    var endPointUrl = UrlConstants.UpdateActivity;
   return this.http.put<any>(endPointUrl, activity);
  }
  
  RemoveFromResults(activityId:string, uid:string){
    var endPointUrl = UrlConstants.DeleteActivityResult + "?activityID=" + activityId + "&UID=" + uid;
    return this.http.delete<any>(endPointUrl);
  }
  
  
  CreateNewSession(sessionDescription:string){
    var endPointUrl = UrlConstants.CreateNewSession;
    
    let data = {
      description : sessionDescription  
    }
    return this.http.post<any>(endPointUrl, data);
  }
  
  GetActivityTemplates(){
    var endPointUrl = UrlConstants.GetActivityTemplate;
    return this.http.get<any>(endPointUrl);
  }
  
  GetActivityBySessionCode(sessionCode:string){
    let participantID = this.datastoreService.GetParticipantID(sessionCode);
    var endPointUrl = UrlConstants.GetActivityBySessionCode + "/" + sessionCode + "?participantID=" + participantID;
    return this.http.get<any>(endPointUrl);
  }  

  UpdateActivityStatus(activityID:string, status:string, maxVoteCount : number, maxVoteCountPerColumn : boolean){
    let data = {
      activityID: activityID,
      status: status,
      maxVoteCount : maxVoteCount,
      maxVoteCountPerColumn : maxVoteCountPerColumn
    };
    var endPointUrl = UrlConstants.UpdateActivityStatus;
    return this.http.post<any>(endPointUrl,data);

  }
  

  AddActivityResult(activityID:string, sessionCode:string,uid:string,vote:number, description:string, groupUID:string){
    //get current participant id 
    let participantID = this.datastoreService.GetParticipantID(sessionCode);
    
    let data = {
      activityID: activityID,
      sessionCode: sessionCode,
      uid: uid,
      vote: vote,
      description: description,
      participantID: participantID,
      groupUID: groupUID
    };

    var endPointUrl = UrlConstants.AddActivityResult;

    return this.http.post<any>(endPointUrl,data);
  }
  
  GetActivityResult(sessionCode:string, activityID:string){
    var endPointUrl = UrlConstants.GetActivityResult + "/" + sessionCode + "/" + activityID;
    return this.http.get<any>(endPointUrl);
  }
  
  GetActivitySession(sessionCode:string){
    var endPointUrl = UrlConstants.GetActivitySessionBySessionCode + "/" + sessionCode;
    return this.http.get<any>(endPointUrl);
  }
  
  UpdateActivitySession(activitySession:ActivitySession){
    var endPointUrl = UrlConstants.UpdateActivitySession;
    return this.http.put<any>(endPointUrl, activitySession);

  }
    
    
  
  Log(description:string){
    console.log(description);
  }
  
  
  test(){
    console.log('getting it now...............');
   // this.http.get('https://6n3nyjsit7.execute-api.us-west-1.amazonaws.com/prod/subscriber/1').subscribe((x)=> console.log(JSON.stringify(x)));

    let data = {
      firstName: "dexter45",
            lastName: "baga",
            email: "mye4mail@mailthis.co",
            phone: "5566445",
            cognitoUID: "abc-defg"
    };
    
    this.http.post<any>('https://6n3nyjsit7.execute-api.us-west-1.amazonaws.com/prod/subscriber', data).subscribe(data => {
        console.log("post result : " + JSON.stringify(data));
    }, (error)=> console.log("error in post : " + JSON.stringify(error)));
  }
  
}
