import { Injectable } from '@angular/core';
import { CanActivate,CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {Auth} from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router){};
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return Auth.currentAuthenticatedUser().then(() => { return true; })
      .catch(() => { 
        this.router.navigate(['auth'], {
          queryParams: {
            url: state.url
          }
        });
        return false;
      });
  }
  
  canActivateChild(){
    return true;
  }
  
}
