<div #list class="list-container">
    <div class="flex-container">
	    <div name="{{group.UID}}" (keydown)="onListDescriptionKeydown($event)" *ngIf="activity.Status !== 'VOTE' && activity.Status !== 'CAPTURE'" class="flex-child list-title" (mouseenter)="enterActivityDescriptionEditbox(group.Description)" (mouseleave)="updateItem()" (blur)="updateItem()" (change)="onTextValueChange($event)" (ngModelChange)="onActivityDescriptionChange($event)" placeholder="Type list description here"  text-editable [(ngModel)]="group.Description"><strong>{{group.Description}}</strong></div>
	    <div *ngIf="activity.Status == 'VOTE' || activity.Status == 'CAPTURE'" class="flex-child list-title"><strong>{{group.Description}}</strong></div>

        <div *ngIf="activity.Status !== 'VOTE' && activity.Status !== 'CAPTURE'" class="flex-child-actions">
            <!--<button style="margin-left:-15px" mat-icon-button [matMenuTriggerFor]="appMenu"><mat-icon class="material-icons color_blue">more_vert</mat-icon></button>-->
            <!--<mat-menu #appMenu="matMenu">-->
            <!--   <button mat-menu-item (click)="clearList(group.UID)" >Clear List</button>-->
            <!--</mat-menu>-->
        </div>   
    </div>
    
    <!--<div *ngIf="(activity.Status == 'PAUSED') || participantMode == true" class="list-action-button-wrapper">-->
    <!--    <div  (keyup)="onAddCardDescriptionKeydown($event)" class="add-card-input" text-editable placeholder="Type new description and hit Enter key to add"></div>-->
    <!--</div>-->
    <div *ngIf="(activity.Status == 'PAUSED')" class="list-action-button-wrapper">
        <button (click)="addNewCard()" mat-raised-button color="primary" ><mat-icon>add_circle_outline</mat-icon>Add New Card</button>
        <!--<div  (keyup)="onAddCardDescriptionKeydown($event)" class="add-card-input" text-editable placeholder="Type new description and hit Enter key to add"></div>-->
    </div>    
	
	<div class="list drag-drop-target" (dragover)="allowDrop($event)" (drop)="drop($event)">
	<!--<div class="list">-->
			<app-one-box-card class="cards" *ngFor="let data of group.Cards" [data]="data"  [attr.data-group-uid]="group.UID" [groupUID]="group.UID" [participantMode]="participantMode"></app-one-box-card>
			<div (dragover)="allowDrop($event)" class="empty-drag-drop-target"></div>
	</div>

    <div class="list-bottom-spacer"></div>

</div>