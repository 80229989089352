import { Component, OnInit,ChangeDetectorRef , NgZone } from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState , FormFieldTypes} from '@aws-amplify/ui-components';
import { ActivatedRoute,Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TokenService } from '../core/services/token.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  formFields: FormFieldTypes | undefined;
  title = 'amplify-angular-auth';
  user: CognitoUserInterface | undefined;
  authState : AuthState | undefined;
  authAction : string;

  constructor(private ref: ChangeDetectorRef, private route:ActivatedRoute, private tokenService: TokenService, private router:Router,private ngZone:NgZone) {
    this.authAction = 'signin';
    this.formFields = [
    {
      type: "email",
      label: "E-mail Address",
      placeholder: "Enter your email address",
      required: true,
    },
    {
      type: "password",
      label: "Password",
      placeholder: "Enter your password",
      required: true,
    },
    ];
  
  }

  ngOnInit() {
    
    const rootUrl : string = "";
    let referralUrl : string = "";
    
    const urlAction =  this.route.queryParams.pipe(
      filter((params: any)=> {
        return params.url
        })
    );

    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();
      
      if(authState === 'signedin'){
        this.tokenService.storeToken(this.user.signInUserSession.idToken.jwtToken);
        this.tokenService.storeRefreshToken(this.user.signInUserSession.refreshToken.token);
        
        console.log(JSON.stringify(authState));
        console.log(JSON.stringify(this.user));   
        
        this.route.queryParams.subscribe(params => {
            if(params["url"]){
             this.ngZone.run(()=> this.router.navigateByUrl(params["url"]));
            }else {
              this.ngZone.run(()=> this.router.navigateByUrl(rootUrl));
            }
          });
        
      }
  
    });
    
    const authAction =  this.route.queryParams.pipe(
        filter((params: any)=> {
          return params.action
          })
      );
      
    authAction.subscribe(x=> {
      this.authAction = x.action.toLowerCase(); 
      if(this.authAction !== 'signin' && this.authAction !== 'signup'){
        this.authAction = 'signin';
      }
      
   
      
        
    });      
      
  
    
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }
}