import { Injectable } from '@angular/core';
import { Activity,ActivityTemplateDataItem } from '../models/activitytemplate.model';
import { DataService } from './data.service';
import { DatastoreService } from './datastore.service';


@Injectable({
  providedIn: 'root'
})
export class CurrentActivityService {

  activity : Activity;

  constructor(public dataService:DataService, public dataStoreService:DatastoreService) { }
  
  LoadActivity(sessionCode:string){
    this.dataService.GetActivityBySessionCode(sessionCode).subscribe(data=>{
      this.activity = data;
    }, error=>{console.log(error);});
  }
  
 addItem(groupUID:string) {
    var newItem = new ActivityTemplateDataItem();
    newItem.Description = '';
    newItem.Vote = 0;
    newItem.UID = Date.now().toString();
    
    this.activity.Template.Lists.find(group=> group.UID === groupUID).Cards.push(newItem);

  }  
  
  DeleteDataItem(itemUID:string, groupUID:string){
    this.activity.Template.Lists.filter(groupItem => groupItem.UID === groupUID).forEach(group=> {
      group.Cards.forEach((item, index) => {
        if (item.UID == itemUID) {
          group.Cards.splice(index, 1);
        
          this.dataService.UpdateActivity(this.activity).subscribe((data) => {
            this.dataStoreService.sessionData.activity = this.activity;
          });
        }
      });
    },
    (error) => {
      console.log('error updating activity : ' + JSON.stringify(error));
    });

  }
  
  UpdateActivity(){
    console.log(JSON.stringify(this.activity));
    this.dataService.UpdateActivity(this.activity).subscribe(
      (data) => {
        this.dataStoreService.sessionData.activity = this.activity;
        return;
      },
      (error) => {
        console.log('error updating activity : ' + JSON.stringify(error));
        return;
      }
    );  
  }
 
  
  
}
