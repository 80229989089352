import { Component, OnInit } from '@angular/core';
import { DatastoreService } from '../../../core/services/datastore.service';
import { Router } from '@angular/router';
import { DataService } from '../../../core/services/data.service';
import { Activity, ActivitySession } from 'src/app/core/models/activitytemplate.model';
import { WaitStatusComponent } from '../../shared/wait-status/wait-status.component';
import { MatDialog,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  waitStatus : boolean = true;
  sessionCode : string = "";
  activity : Activity;
  activitySession: ActivitySession;
  loadedData:boolean = false;
  voteCount: number = 0;
  
  constructor(public dialog: MatDialog ,private dataStoreService : DatastoreService, private router : Router, private dataService: DataService) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.sessionCode = this.dataStoreService.sessionData.sessionCode ?? "" ;

    if(this.sessionCode.length == 0){
      this.router.navigate(['/']);
      return;
    }
    
    this.dataStoreService.sessionData.participantSession = true;
    
    this.dataService
      .GetActivitySession(this.dataStoreService.sessionData.sessionCode)
      .subscribe((data)=>{
        this.activitySession = data;
        this.dataStoreService.sessionData.activitySession = data;
        this.dataStoreService.Save();
        
      }, error => console.log('error getting activity session data : ' + error));
      
    this.dataService
      .GetActivityBySessionCode(this.dataStoreService.sessionData.sessionCode)
      .subscribe((data) => {
        if(data !== null){
          this.dataStoreService.sessionData.activity = data;
          this.dataStoreService.sessionData.activity.Template.Lists.forEach(group=>{
            group.Cards = group.Cards.sort((num1,num2)=> num1.SequenceNumber - num2.SequenceNumber);
          });  
  
          this.activity = this.dataStoreService.sessionData.activity;
      
          let self = this;
          setInterval(function(){
              let total = 0;
              
              let maxVotes = self.activity.Template.VoteSettings.MaxVoteCount;
  
              self.activity.Template.Lists.forEach(list=>{
                list.Cards.forEach(card=>{
                  total = total + card.Vote;
                });
                
              });
  
              total = (self.activity.Template.VoteSettings.ExclusiveVote === true  ? (maxVotes * self.activity.Template.Lists.length) : maxVotes  ) - total;
  
              if(total !== self.voteCount && total >= 0){
                self.voteCount = total;
              }
            },1000);
        
  
          this.waitStatus = false;
          if(this.activity.Status === "VOTE" || this.activity.Status === "CAPTURE"){
            
            if(this.activity.Status === "VOTE"){
                  //reset votes
                  this.activity.Template.Lists.forEach(group=>{
                    group.Cards.forEach(data=>{
                      data.Vote = 0;
                    });
                  });
            }
            this.waitStatus = false;
          }else {
            this.waitStatus = true;
          }
          
          this.dataStoreService.Save();
    
          this.loadedData = true;
        }
        
        if(this.waitStatus  && this.sessionCode.length > 0){
          this.ShowWaitStatus();
        } 
    }, error=> console.log("Error retrieving activty by session code : " + error));    
  
 }

  ngOnInit(): void {


    
  }
  
  ShowWaitStatus() {
    var self = this;
    //show timer gauge dialog
    let dialogRef = this.dialog.open(WaitStatusComponent, {
      data:{
        sessionCode:this.sessionCode,
        status: (this.activity == null ? null : this.activity.Status)
      },
      height: '150px',
      width: '400px',
      disableClose: true
    });
    
    //reset activity status to PAUSED after voting
    dialogRef.afterClosed().subscribe(result=>{
      this.dataService.GetActivityBySessionCode(this.sessionCode).subscribe(data=>{
            this.activity = data;
            if(this.activity.Status === "VOTE" || this.activity.Status === "CAPTURE"){
              this.waitStatus = false;
            };
        });
      
      });
  }
  
  updateItem() {

    this.dataService.UpdateActivity(this.activity).subscribe(
      (data) => {
        this.dataStoreService.Save();
      },
      (error) => {
        console.log('error updating activity : ' + JSON.stringify(error));
      }
    );  
  
  }    

  SubmitResponse(){

    //use promises so we have an easy mechanism to trigger redirect one all requests are done 
    var promises = [];

    this.activity.Template.Lists.map((group)=>{
        group.Cards.map((item)=>{
          promises.push(this.dataService.AddActivityResult(this.activity.ActivityID, this.activity.SessionCode, item.UID,item.Vote,item.Description, group.UID).toPromise());
        });
    })

    if(promises.length == 0){
      promises.push(this.dataService.AddActivityResult(this.activity.ActivityID, this.activity.SessionCode, '',0,'','').toPromise());
    }  

    Promise.all(promises).catch(error => console.log('Error submitting response ' + JSON.stringify(error))).finally(()=>{
        this.router.navigate(["/","workspace","session"]);
    });

  }


}