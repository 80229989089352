
<div id="onelistmain" class="container-fullscreen">
    <div id="onelist" class="lists"> 
        <app-one-box class="list-container" *ngFor="let list of activity.Template.Lists" [group]="list" [sortByVote]="false" [participantMode]=participantMode> </app-one-box>
    </div>
</div> 
  

<!--<div id="oneboxmain" class="container-fullscreen box-center">-->
<!--        <div id="onebox" class="box-vertical-center">-->
<!--                <div (blur)="updateActivity()" class="activity-description" text-editable [(ngModel)]="activity.Description">{{activity.Description}}</div>-->
<!--                <div class="box-center">-->
<!--                 <div id="imgContainer" class="box-center" *ngFor="let item of activity.Template.Lists[0].Cards; let x = index">-->
<!--                     <img src="{{item.Url}}" />-->
<!--                     <p>{{item.Vote}}</p>-->
<!--                </div>-->
<!--                </div>-->
<!--        </div>-->
<!--</div>-->

