import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'


@Component({
  selector: 'app-new-activity-dialog',
  templateUrl: './new-activity-dialog.component.html',
  styleUrls: ['./new-activity-dialog.component.scss']
})
export class NewActivityDialogComponent implements OnInit {

  constructor(public dialogRef:MatDialogRef<NewActivityDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  CreateNewActivity(){
    this.dialogRef.close(true);
  }
  
  Cancel(){
    this.dialogRef.close(false);
  }
}
