import { Component, OnInit,Inject } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from '../../../core/services/data.service';
import { ActivityStatus } from '../../../core/constants/stringConstants';
import { Router } from '@angular/router';

@Component({
  selector: 'wait-status',
  templateUrl: './wait-status.component.html',
  styleUrls: ['./wait-status.component.scss']
})
export class WaitStatusComponent implements OnInit {

  activityNotAvailable:boolean=false;
  contributed : boolean = false;
  sessionCode: string;
  constructor(public dialogRef:MatDialogRef<WaitStatusComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private dataService:DataService, private router : Router) { 
    this.sessionCode = data.sessionCode;
  }

  ngOnInit(): void {
    this.activityNotAvailable = false;
    
    if(this.data.status !== null){
    this.contributed = false;
     if(this.data.status === "VOTE" || this.data.status === "CAPTURE"){
        this.dialogRef.close();
      } else if(this.data.status === "CONTRIBUTED"){
        this.contributed = true;
         setTimeout(()=>{this.contributed = false;}, 3000);
      }else{
        this.activityNotAvailable = true;
        setTimeout(()=>{this.activityNotAvailable = false;}, 3000);
      }
    }

  }

  JoinActivity(){
    this.dialogRef.close();
    this.router.navigate(["/","workspace","session"]);

  }
}
