import { Component } from '@angular/core';
import {delay} from 'rxjs/operators';
import {SpinnerService} from './core/services/spinner.service';
import {Auth} from '@aws-amplify/auth';
import {MatSnackBar} from '@angular/material/snack-bar';
import { PrivacyDialogComponent } from './features/shared/components/privacydialog/privacydialog.component'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SpikeOnIt';
  loading: boolean = false;
  
  constructor(
    private _loading: SpinnerService,
    private _snackBar: MatSnackBar
  ){ }
  
   ngOnInit() {
    this.listenToLoading();
    
    let localStorage = window.localStorage;
    let privacyOK = localStorage.getItem("privacyOK");
    
    if(privacyOK == null){
      const bar = this._snackBar.openFromComponent(PrivacyDialogComponent, {horizontalPosition: "center", verticalPosition: "bottom", 
                data: {preClose: () => {bar.dismiss()} }, panelClass:['privacy-policy']});
    }
    
  }
  
  closePrivacyPolicyDialog(){
    this._snackBar.dismiss();
  }
  
   listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
  
  signOut(){
    Auth.signOut();
  }
  

  
}
  