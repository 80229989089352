import { Component, OnInit, Input } from '@angular/core';
import { ActivityTemplateDataItem, Activity } from '../../../../core/models/activitytemplate.model';
import { DatastoreService } from '../../../../core/services/datastore.service';
import { DataService } from '../../../../core/services/data.service';
import { CardPropertiesComponent } from '../card-properties/card-properties.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';



@Component({
  selector: 'app-one-box-card',
  templateUrl: './one-box-card.component.html',
  styleUrls: ['./one-box-card.component.scss'],
  animations: [
    trigger('onDragEnd', [
      // ...
      state('startDrag', style({
        backgroundColor: 'green'
      })),
      state('endDrag', style({
        backgroundColor: '#3390FF'
      })),
      transition('startDrag => endDrag', [
        animate('.25s')
      ])
    ]),
  ],
})
export class OneBoxCardComponent implements OnInit {
  @Input() participantMode:boolean=false;
  @Input() groupUID:string;
  @Input() data : ActivityTemplateDataItem;
  activity : Activity;
  selectedUID: string = '';
  selectedItemDescription: string = '';
  originalItemDescription: string = '';
  allowDragDrop:boolean=false;
  loadedData:boolean=false;
  selectedActivityDescription: string = '';
  originalActivityDescription: string = '';
  groupedCardIsDisplayed:boolean = false;
  cardDescriptionMaxLength:number = 80;
  
  fontColorWhite:string = "#ffffff";
  fontColorBlack:string = "#000000";
  voteFontColor:string = "#000000";
  constructor(public dataStoreService:DatastoreService, private dataService:DataService, public dialog: MatDialog) { 
 
  }

  ngOnInit(): void {
    this.allowDragDrop = !this.participantMode;  

    this.activity = this.dataStoreService.sessionData.activity;
    this.loadedData = true;
  }
  
  ngAfterViewInit() {

      function handleDragEnter(e) {
        this.classList.add('over');
      }
      
      function handleDragLeave(e) {
        this.classList.remove('over');
      }  
      
      let items = document.querySelectorAll('.drag-drop-target');
        items.forEach(function(item) {
          item.addEventListener('dragenter', handleDragEnter, false);
          item.addEventListener('dragleave', handleDragLeave, false);
        });
        
  }
  
  ShowOrHideGroupedCards(){
    this.groupedCardIsDisplayed = !this.groupedCardIsDisplayed;  
  }
  
  
  removeFromCardGroup(uid:string){
    //get the item
    let item = this.data.Group.filter(x=> x.UID == uid)[0];
    let list = this.activity.Template.Lists.filter(x=> x.UID == this.groupUID)[0];
    
    //add to the list
    list.Cards.push(item);
    
    //remove from card group
    this.data.Group.splice(this.data.Group.findIndex(x=> x.UID == uid),1);
    
    //save updates
    this.dataService.UpdateActivity(this.activity).subscribe(
      (data) => {
        this.dataStoreService.sessionData.activity = this.activity;
        this.dataStoreService.Save();
        return;
      },
      (error) => {
        console.log('error updating activity : ' + JSON.stringify(error));
        return;
      }
    );    
    
  }
  
  updateItem() {

    if(this.activity.Status === 'CAPTURE'){
      return;
    }
    
    //check if entry has changed
    if (
      (this.selectedActivityDescription === this.originalActivityDescription &&
        this.selectedActivityDescription != '' &&
        this.originalActivityDescription != '') ||
      (this.selectedItemDescription === this.originalItemDescription &&
        this.selectedItemDescription != '' &&
        this.originalItemDescription != '')
    ) {

       this.resetTrackingFields();
      return;
    }

    this.resetTrackingFields();

    console.log(JSON.stringify(this.activity));
    this.dataService.UpdateActivity(this.activity).subscribe(
      (data) => {
        this.dataStoreService.Save();
        return;
      },
      (error) => {
        console.log('error updating activity : ' + JSON.stringify(error));
        return;
      }
    );
  }  

 deleteItem(uid:string) {
    this.activity.Template.Lists.forEach(group=>  group.Cards.forEach((item, index) => {
      if (item.UID == uid) {
        group.Cards.splice(index, 1);

        this.dataService.UpdateActivity(this.activity).subscribe((data) => {
        this.dataStoreService.sessionData.activity = this.activity;

        },
        (error) => {
          console.log('error updating activity : ' + JSON.stringify(error));
        }
    );
    
      }
    }));
    
    this.resetTrackingFields();
  }
  
  viewSettings(uid:string){
    let dialogRef = this.dialog.open(CardPropertiesComponent, {
      data: {
        backgroundUrl:this.data.Url,
        backgroundColor:this.data.BackgroundColor,
        fontColor: this.data.FontColor,
        fontSize:this.data.FontSize
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('newactc ' + result);
      if (result) {
        this.data.Url = result.backgroundUrl;
        this.data.BackgroundColor = result.backgroundColor;
        this.data.FontColor = result.fontColor;
        this.data.FontSize = result.fontSize;
        this.dataService.UpdateActivity(this.activity).subscribe((data) => {
          this.dataStoreService.sessionData.activity = this.activity;

          }, (error) => {
                          console.log('error updating activity : ' + JSON.stringify(error));
                        });

      }
    });
  }
  
  enterItemDescriptionEditbox(uid: string, description: string) {
    this.resetTrackingFields();
    
    this.selectedUID = uid;
    this.selectedItemDescription = description;
    this.originalItemDescription = description;
  }

  enterActivityDescriptionEditbox(description: string) {
    this.resetTrackingFields();
    this.selectedActivityDescription = description;
    this.originalActivityDescription = description;
  }

  onItemDescriptionChange(value: string) {
    this.selectedItemDescription = value;
  }

  onActivityDescriptionChange(value: string) {
    this.selectedActivityDescription = value;
  }
  
  resetTrackingFields(){
    this.selectedUID  = '';
    this.selectedItemDescription = '';
    this.originalItemDescription = '';

    this.originalActivityDescription = '';
    this.selectedActivityDescription = '';
  }
  
  increaseVote(){
    //check how many votes we already have
    let voteCount = 0;
    this.activity.Template.Lists.forEach(list=>{
      list.Cards.forEach(card=>{
        voteCount = voteCount + card.Vote;
      })
    });
    
    if(this.activity.Template.VoteSettings.ExclusiveVote === true){
      //check if the column votes is already maxvotecount
      var groupUID = this.activity.Template.Lists.filter(list=> list.Cards.filter(card=> card.UID == this.data.UID).length > 0);
      var votes = this.activity.Template.Lists.filter(list=> list.UID == groupUID[0].UID)[0].Cards.reduce((total, cur)=> total + cur.Vote, 0);
      //console.log('votes count : ' + votes + '   max vote : ' + this.activity.Template.VoteSettings.MaxVoteCount);
      if(votes < this.activity.Template.VoteSettings.MaxVoteCount){
        this.data.Vote = this.data.Vote + 1;
      }
    }else if(voteCount < this.activity.Template.VoteSettings.MaxVoteCount){
      this.data.Vote = this.data.Vote + 1;
    }
  }
  

  resetVote(){
    this.data.Vote = 0;
  }
  
  
  
  get border() {
    let border = {}; 
    border[`background-color`] = this.data.BackgroundColor;
    let element = document.getElementById(this.data.UID);
    var style = element.getAttribute("style");
    //console.log("style .... " + style);
    if(style == null){
      border[`border-left`] = '5px solid #' + 
      Math.floor(Math.random()*16777215).toString(16)
 
    }else {
      border[`border-left`] = element.getAttribute("style");
    }
    
    if(['','#FFFFFF','#CFD8D7','#BCE7FD','#21FA90'].includes(this.data.BackgroundColor)){
      this.voteFontColor = this.fontColorBlack;
    }else {
      this.voteFontColor = this.fontColorWhite;
    }
    //console.log('votefontcolor : ' + this.voteFontColor + '   backcolor : ' + this.data.BackgroundColor);
    return border;
    
  }  
  
  onCardDescriptionKeydown($event) {
    if($event.key === "Delete" || $event.key === "Backspace"){
      //deleting chars is always allowed
    } else if($event.key === "Enter" ){
      $event.preventDefault();
    } else if($event.target.innerText.trim().length > this.cardDescriptionMaxLength){
      $event.preventDefault();
    }      
  }    
  
  //********** drag and drop *******
  dragStart(ev) {
    if(this.allowDragDrop === false)
      return;
    let sourceID = ev.target.id;
    let card = document.getElementById(sourceID);
    card.classList.add("start-drag");
    ev.dataTransfer.setData("id", ev.target.id);
  }
  
  allowDrop($event) {
    
    // if(this.allowDragDrop === false)
    //   return;
    $event.preventDefault();
  }

}
