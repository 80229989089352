<div class="nav-container">

  <mat-toolbar color="primary" class="mat-elevation-z1">
      <mat-toolbar-row>

            <div class="nav-menu-container"><button class="nav-menu" mat-icon-button><mat-icon class="nav-appname-icon" style="vertical-align: -webkit-baseline-middle;color:white">offline_bolt</mat-icon></button></div>
    		<div class="nav-app-name">SpikeOnit</div>

     </mat-toolbar-row>
  </mat-toolbar>
  
  <mat-sidenav-container class="nav-sidenav-container">
    <mat-sidenav-content style="overflow:hidden">  
    
		<div class="container-fullscreen box-vertical vertical-30vh">
			<h2>Create New Session</h2>
			<h3>Provide a name for this session</h3>
			<div>
				<mat-form-field >
					<input maxLength="50" matInput type="text" [(ngModel)]="description"  placeholder="Session Name or Description">
				</mat-form-field>
			</div>
			<div *ngIf="showDescriptionRequiredWarning" style="color:red;">
					Session Name or Description is required.
			</div>
			<div>
				<a mat-flat-button color="primary" id="startSession" name="startSession" [routerLink]="" (click)="CreateNewSession()">Create Session</a>
			</div>
		</div>

	    </mat-sidenav-content>
  </mat-sidenav-container>
   
  
</div>
