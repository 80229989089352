import { Component, OnInit, Input } from '@angular/core';
import { ActivityTemplateData, Activity, ActivityTemplateDataItem } from '../../../../core/models/activitytemplate.model';
import { DatastoreService } from '../../../../core/services/datastore.service';
import { DataService } from '../../../../core/services/data.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @Input() participantMode:boolean=false; 
  @Input() group : ActivityTemplateData;
  @Input() removeListFunction : (args:string)=>void;
  activity: Activity;
  allowDragDrop:boolean=false;
  selectedListDescription: string = '';
  originalListDescription: string = '';
  activityDescriptionMaxLength:number = 65;
  cardDescriptionMaxLength:number = 80;
  shouldDisableList: boolean = false;
  constructor(public dataStoreService:DatastoreService, private dataService:DataService) { 
    
    this.allowDragDrop = !this.participantMode;
    this.activity = dataStoreService.sessionData.activity;
    dataStoreService.sessionData.cardInDragState=false;
    
  }

  ngOnInit(): void {
    if(this.activity.LastAction == "VOTE"){
      this.group.Cards.sort((v1,v2)=> v2.Vote - v1.Vote);
    }
  }
 
 
  resetTrackingFields(){
    this.originalListDescription = '';
    this.selectedListDescription = '';
  }
  
  updateItem() {

    //check if entry has changed
    if (
      (this.selectedListDescription === this.originalListDescription &&
        this.selectedListDescription != '' &&
        this.originalListDescription != '') ) {

       this.resetTrackingFields();
      return;
    }

    this.resetTrackingFields();

    
    this.dataService.UpdateActivity(this.activity).subscribe(
      (data) => {
        this.dataStoreService.sessionData.activity = this.activity;
        this.dataStoreService.Save();
        return;
      },
      (error) => {
        console.log('error updating activity : ' + JSON.stringify(error));
        return;
      }
    );  
  
  }
  
 updateActivity(){
       this.dataService.UpdateActivity(this.activity).subscribe(
      (data) => {
        this.dataStoreService.Save();
        return;
      },
      (error) => {
        console.log('error updating activity : ' + JSON.stringify(error));
        return;
      }
    );  
 }
  
 enterActivityDescriptionEditbox(description: string) {
    this.resetTrackingFields();
    this.selectedListDescription = description;
    this.originalListDescription = description;
  }


  onActivityDescriptionChange(value: string) {
    this.selectedListDescription = value;
  }  
  
  onTextValueChange($event){
    //console.log('ontextvaluechange ' + JSON.stringify($event));
  }
  
  addItem(groupUID:string) {
    var newItem = new ActivityTemplateDataItem();
    newItem.Description = '';
    newItem.Vote = 0;
    newItem.UID = Date.now().toString();
    newItem.Group = [];
    this.group.Cards.unshift(newItem);
    

    //we have to delay setting the focus to give the model
    //enough time to render 
    setTimeout(function(){
      let element = document.querySelector<HTMLInputElement>('[ng-reflect-name="' + newItem.UID + '"]');
      var s = window.getSelection(),
          r = document.createRange();
      r.setStart(element, 0);
      r.setEnd(element, 0);
      s.removeAllRanges();
      s.addRange(r);      
    },300);

  }  
  
  removeList(groupUID:string){
    console.log('removelist is called')
    this.removeListFunction(groupUID);
  }
  
  clearList(groupUID:string){
    let lists = this.activity.Template.Lists.filter(list=> list.UID == groupUID);
    if(lists.length > 0){
      lists[0].Cards = [];
    }
    
    if(!this.participantMode){
        this.updateActivity();
    }
  }
  
  onListDescriptionKeydown($event) {
    
    if($event.key === "Delete" || $event.key === "Backspace"){
      //deleting chars is always allowed
    } else if($event.key === "Enter" ){
      this.updateItem();
      let addNewListButton = document.getElementById("addNewList");
      addNewListButton.focus();
      $event.preventDefault();
    } else if($event.target.innerText.trim().length > this.activityDescriptionMaxLength){
      $event.preventDefault();
    }
    
    
  }  
  
  onAddCardDescriptionKeydown($event){
    if($event.key === "Delete" || $event.key === "Backspace"){
      //deleting chars is always allowed
    } else if($event.key === "Enter" &&  $event.target.innerText.trim().length > 0){
      var newItem = new ActivityTemplateDataItem();
      newItem.Description = $event.target.innerText.trim();
      newItem.Vote = 0;
      newItem.UID = Date.now().toString();
      newItem.Group = [];
      this.group.Cards.unshift(newItem);
      $event.target.innerText = "";
      $event.preventDefault();
      
      if(!this.participantMode){
        this.updateActivity();
      }
      
    } else if($event.target.innerText.trim().length > this.cardDescriptionMaxLength){
      $event.preventDefault();
    } else if ($event.key === "Enter" &&  $event.target.innerText.trim().length == 0){
      $event.preventDefault();
    }
  
       
  }

  //********************** DRAG AND DROP SECTION ******************
  allowDrop($event) {
   
    $event.preventDefault();
  }

  drop($event) {
    
    if(this.allowDragDrop === false){
      return;
    }
    
    $event.preventDefault();
    
    let successfulDragDrop:boolean = true;
    
    const sourceID = $event.dataTransfer.getData("id");
    
    let target = $event.target;
    const targetClassName = target.className;
    
    let grouping : boolean = false;
    if(targetClassName.includes('drag-drop-target')){
      grouping = true;
    }
    
    //get to the cards node for the dragged card
    let sourceCardNode = document.getElementById(sourceID);
    let sourceCardsNode = document.getElementById(sourceID);
    while(sourceCardsNode.className.indexOf("cards") < 0){
      sourceCardsNode = sourceCardsNode.parentElement;
    }
    
    let sourceGroupUID = sourceCardsNode.getAttribute('data-group-uid');
    let sourceGroup = this.activity.Template.Lists.filter(x=> x.UID == sourceGroupUID)[0];
        
    let targetGroupUID = this.group.UID;
       
    //check if it is dropped to an empty target
    if(targetClassName == 'empty-drag-drop-target'){
      
      let data = sourceGroup.Cards.filter(x=> x.UID === sourceID);
      this.group.Cards.push(data[0]);
      sourceGroup.Cards.splice(sourceGroup.Cards.findIndex(x=> x.UID == sourceID), 1);

    }else if(grouping){
      
      //get the target card
      let cardNodeTarget = $event.target;
      while (cardNodeTarget.className.indexOf("card-node") < 0) {
        cardNodeTarget = cardNodeTarget.parentNode;
      }  
      
      //get the target card uid
      let targetCardId = cardNodeTarget.id;

      if(targetCardId !== sourceID){
        //copy the source card data into target card
        //move the actual data if it was dragged to a different list
        let data = sourceGroup.Cards.filter(x=> x.UID === sourceID);
        
        let targetCards = this.group.Cards.filter(x=> x.UID == targetCardId);
        
        targetCards[0].Group.push(data[0]);
  
        sourceGroup.Cards.splice(sourceGroup.Cards.findIndex(x=> x.UID == sourceID), 1);
      }else {
        successfulDragDrop = false;
      }
      

    }else {

      //get reference to target for cards
      let cardsTarget = $event.target;
      
      //get the list target
      let listTarget = $event.target;
      while (listTarget.className.indexOf("list") < 0) {
        listTarget = listTarget.parentNode;
      }
      
      //get to the closest parent cards node
      while (cardsTarget.className.indexOf("cards") < 0) {
        cardsTarget = cardsTarget.parentNode;
      }  
      
      
      //get the last card in the list
      let lastCardInList = listTarget.lastElementChild;
      
      //append sourceCardNode at the end of list if target node is the last node 
      if(lastCardInList == cardsTarget){
        listTarget.insertBefore(sourceCardsNode, cardsTarget);
        listTarget.insertBefore(cardsTarget,sourceCardsNode);
      }else {
        listTarget.insertBefore(sourceCardsNode, cardsTarget);
      }
      
      //move the actual data if it was dragged to a different list
      if (targetGroupUID !== sourceGroupUID){

        let cardNodeTarget = $event.target;

        //find the index of the target card
        while (cardNodeTarget.className.indexOf("card-node") < 0) {
          cardNodeTarget = cardNodeTarget.parentNode;
        }   
        let targetCardIndex = this.group.Cards.findIndex(x=> x.UID == cardNodeTarget.id);

        //copy the data from source to target
        let data = sourceGroup.Cards.filter(x=> x.UID === sourceID);
        this.group.Cards.splice(targetCardIndex, 0, data[0]);
        //remove from source
        sourceGroup.Cards.splice(sourceGroup.Cards.findIndex(x=> x.UID == sourceID), 1);
      }
          
      //after item is dropped, we need to adjust the sequence numbers for the list in activity data
      for(let index = 0; index < listTarget.children.length; index++ ){
        for(let childIndex = 0; childIndex < listTarget.children[index].children.length; childIndex++ ){
          if(listTarget.children[index].children[childIndex].className.indexOf("card") >= 0){
            let uid = listTarget.children[index].children[childIndex].id;
            
            //find the UID in activity data and update SequenceNumber based on index
            var item = this.group.Cards.filter(item=> item.UID == uid);
            if(item.length > 0){
              item[0].SequenceNumber = index;
            }
            
          } 
        }
      }

    }
    
    if (successfulDragDrop){
      this.dataService.UpdateActivity(this.activity).subscribe(
        (data) => {
          this.dataStoreService.sessionData.activity = this.activity;
          this.dataStoreService.Save();
          return;
        },
        (error) => {
          console.log('error updating activity : ' + JSON.stringify(error));
          return;
        }
      );  
    }

    sourceCardNode.classList.remove('start-drag');
    sourceCardNode.classList.add('end-drag');
    
    let items = document.querySelectorAll('.drag-drop-target');
      items.forEach(function(item) {
        item.classList.remove('over');
    });
    
    this.dataStoreService.sessionData.cardInDragState=false;
  
   

  }  

}
