import { Component, OnInit,Inject } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { DataService } from '../../../../core/services/data.service';


@Component({
  selector: 'app-vote-dialog',
  templateUrl: './vote-dialog.component.html',
  styleUrls: ['./vote-dialog.component.scss']
})
export class VoteDialogComponent implements OnInit {
  started : boolean = false;
  listCount :number = 0;
  options : FormGroup;
  timer = new FormControl(5, [Validators.min(1), Validators.max(60), Validators.required]);
  countDown = new FormControl(false);
  maxVoteCount = new FormControl(5);
  maxVotePerColumn = new FormControl(false);
  showFeedbackProgress = new FormControl(false);
  
  constructor(fb:FormBuilder, public dialogRef:MatDialogRef<VoteDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private dataService:DataService) {
    this.listCount = data.listCount;
    this.maxVoteCount.patchValue(data.maxVoteCount);
    this.options = fb.group({
      timer : this.timer,
      maxVoteCount : this.maxVoteCount,
      maxVotePerColumn : this.maxVotePerColumn,
      showFeedbackProgress : this.showFeedbackProgress,
      countDown : this.countDown
    });

  }

  ngOnInit(): void {
  }
  
  Go(){
    
    //set acivity status
    //console.log("about to update activity to status " + this.data.status +  '   mavotecount : ' + this.maxVoteCount.value);
    let reset:boolean = true;
    this.dataService.UpdateActivityStatus(this.data.activityID, this.data.status, this.maxVoteCount.value, this.maxVotePerColumn.value).subscribe(data => {
      //console.log('start voting : ' + JSON.stringify(data));
      this.dialogRef.close({
        timerMaxValue : this.timer.value,
        timerIsCountDown : this.countDown.value
        
      });
    }, (error)=>{
      console.log("Error detected while updating activity status to " + this.data.status);
      console.log(JSON.stringify(error));
    });
    
  }
  
  Close(){
      this.dialogRef.close();
  }

 
}
