import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-privacydialog',
  templateUrl: './privacydialog.component.html',
  styleUrls: ['./privacydialog.component.scss']
})
export class PrivacyDialogComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data) { }

  ngOnInit(): void {
  }
  
  acceptPrivacyPolicy(){
    let localStorage = window.localStorage;
    localStorage.setItem("privacyOK","OK");
    this.data.preClose();
  }

}
