<div *ngIf="isLoaded == true" class="nav-container">

  <mat-toolbar color="primary" class="mat-elevation-z1">
      <mat-toolbar-row>
        	<div class="nav-menu-container"><button class="nav-menu" mat-icon-button><mat-icon class="nav-appname-icon">offline_bolt</mat-icon></button></div>
    		<div class="nav-app-name">SpikeOnIt</div>    		
     </mat-toolbar-row>
  </mat-toolbar>
  
  <mat-sidenav-container class="nav-sidenav-container">
    <mat-sidenav-content style="overflow:hidden">  


			<div class="container-fullscreen box-vertical vertical-30vh">
				
				<h2><a href="#">Join A Spike Session!</a></h2>
				<div class="bar">
					<!--<mat-form-field>-->
						<input type="text" [(ngModel)]="sessionCode" class="searchbar" placeholder="Session Code">
					<!--</mat-form-field>-->
					<button id="join" mat-icon-button color="primary" style="margin-bottom:15px;" (click)="JoinSession()"><mat-icon class="join-icon" style="padding-top:1px;padding-right:1px;">play_circle_filled</mat-icon></button>
				</div>
				<div *ngIf="isSessionCodeInValid" style="color:red;">
					Invalid Session Code
				</div>
				<div *ngIf="isSessionCodeRequired" style="color:red;">
					Session Code is required
				</div>				
				<div>
				
						<a mat-stroked-button class="button-spacer" color="primary" (click)="CreateNewSession()"><mat-icon>add_circle_outline</mat-icon> New Retrospective Session</a>
				
						<!--<a mat-stroked-button color="primary" href="#one"><mat-icon>info_outline</mat-icon> Learn More</a>-->
					
				</div>
			</div>

	    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
