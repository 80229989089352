import { Component, OnInit,Inject } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'

@Component({
  selector: 'app-card-properties',
  templateUrl: './card-properties.component.html',
  styleUrls: ['./card-properties.component.scss']
})
export class CardPropertiesComponent implements OnInit {
  
  fontSize = [{value:'18px', display:'smallest'},{value:'24px', display:'small'},{value:'30px', display:'medium'}
          ,{value:'42px', display:'large'},{value:'96px', display:'x-large'},{value:'104px', display:'largest'}];
  colors = ['#FFFFFF','#CFD8D7','#BCE7FD','#21FA90','#AF3B6E','#AD343E','#EAC435','#F2AF29','#4B4E6D','#000000'];
  selectedBackgroundColor:string;
  selectedFontColor:string;
  selectedFontSize:string;
  
  constructor(public dialogRef:MatDialogRef<CardPropertiesComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.selectedBackgroundColor = data.backgroundColor;
    this.selectedFontColor = data.fontColor;
    this.selectedFontSize = data.fontSize;
  }

  ngOnInit(): void {
  }
  

  Close(){
    this.dialogRef.close();
  }  
  
  SaveSettings(){
    this.dialogRef.close({
       backgroundUrl: this.data.backgroundUrl,
       backgroundColor: this.selectedBackgroundColor,
       fontColor: this.selectedFontColor,
       fontSize:this.selectedFontSize
      });
  }

}
