import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  HostListener,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DatastoreService } from '../../../core/services/datastore.service';
import { CurrentActivityService } from '../../../core/services/currentactivity.service';
import { DataService } from '../../../core/services/data.service';
import { WorkspaceSharedService } from '../../../core/services/workspace.shared.service';
import { environment } from '../../../../environments/environment';


import {
  ActivityTemplate,
  Activity,
  ActivitySession,
} from 'src/app/core/models/activitytemplate.model';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';
import { VoteDialogComponent } from '../../shared/components/vote-dialog/vote-dialog.component';
import { SessioninfoComponent } from '../../shared/sessioninfo/sessioninfo.component';
import { NewActivityDialogComponent } from '../../shared/new-activity-dialog/new-activity-dialog.component';
import { ActivityStatus } from '../../../core/constants/stringConstants';
import { Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
})
export class BoardComponent implements OnInit {
  mobileQuery: MediaQueryList;
  activity: Activity;
  activitySession : ActivitySession;
  loadedData: boolean = false;
  loadedSessionData : boolean = false;
  elem: any;
  isFullScreen: boolean;
  appVersion : string;
  
  //timer data
  displayTimer : boolean = false;
  timerMaxValue : number = 5;
  timerIsCountDown : boolean = false;
  
  private _mobileQueryListener: () => void;

  constructor(
    public dialog: MatDialog,
    private dataService: DataService,
    private dataStoreService: DatastoreService,
    public currentActivityService: CurrentActivityService,
    private workspaceSharedService : WorkspaceSharedService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    
    this.dataStoreService.sessionData.participantSession = false;
    this.loadedData = false;
    
    let sessionCode = this.dataStoreService.sessionData.sessionCode ?? "" ;

    if(sessionCode.length == 0){
      this.router.navigate(['/']);
      return;
    }    

 
  }

  ngOnInit(): void {
    this.loadedData = false;
    
    this.dataService
      .GetActivitySession(this.dataStoreService.sessionData.sessionCode)
      .subscribe((data)=>{
        this.activitySession = data;
        this.dataStoreService.sessionData.activitySession = data;
        this.dataStoreService.Save();
        this.loadedSessionData = true;
        this.dataService
          .GetActivityBySessionCode(this.dataStoreService.sessionData.sessionCode)
          .subscribe((data) => {
            if(data !== null){
              this.dataStoreService.sessionData.activity = data;
               this.dataStoreService.sessionData.activity.Template.Lists.forEach(list=>{
                list.Cards = list.Cards.sort((num1,num2)=> num1.SequenceNumber - num2.SequenceNumber);
              });  
              this.dataStoreService.Save();
              
              this.activity = this.dataStoreService.sessionData.activity;
        
              this.loadedData = true;
              
              if(this.dataStoreService.sessionData.newSession == true){
                this.ShowSessionAccess();
              }
                
            }else {
              this.ShowSessionAccess();
            }
            this.dataStoreService.sessionData.newSession = false;
            this.dataStoreService.Save();
          });
        
        
      });
    
   
      
    this.appVersion = environment.appVersion;
    this.chkScreenMode();
    this.elem = document.documentElement;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;


  }
  
  ngAfterContentInit(){
    
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ShowMainMenu(){
    //console.log('showing menu');
  }

  SetDesignMode(isEnabled:boolean){
    this.workspaceSharedService.designMode = isEnabled;
  }


  onSessionDescriptionKeydown($event) {
    if($event.key === "Enter"){
      $event.preventDefault();
      this.updateSessionDescription();
    }
  }
  onActivityDescriptionKeydown($event) {
    if($event.key === "Enter"){
      $event.preventDefault();
      this.updateActivityDescription();
    }
  }  
  
  ShowActivitySession(alwaysShow:boolean = false){
    var title = this.document.getElementById('activitySessionTitle');
    
    if(alwaysShow){
      title.hidden = false;
      return;
    }
    
    if(window.innerWidth <= 600){
      title.hidden = true;
    }else{
      title.hidden = false;
    }
  }

  Vote() {

    //flag acivity to VOTE
    var activityID = this.activity.ActivityID;

    //show timer gauge dialog
    let dialogRef = this.dialog.open(VoteDialogComponent, {
      data: {
        listCount : this.activity.Template.Lists.length,
        maxVoteCount : this.activity.Template.VoteSettings.MaxVoteCount,
        currentStatus:this.activity.Status,
        activityID: activityID,
        status: ActivityStatus.Vote,
        title: 'Collect Votes',
        description: 'Participants will be able to vote on 1 or more items from the current activity board items.'
      },
      width: '500px',
      disableClose: true,
    });


    dialogRef.afterClosed().subscribe((result) => {
     //console.log(JSON.stringify(result));
      if(result){
        //console.log('displaytimer false');
        this.displayTimer = false;
        setTimeout(()=>{
           this.timerMaxValue = result.timerMaxValue;
          this.timerIsCountDown = result.timerIsCountDown;
          this.displayTimer = true;
          this.activity.Status = 'VOTE';
          this.ShowActivitySession();
        }, 600)
       
      }

    });
  }
  
   Capture() {

    //flag acivity to VOTE
    var activityID = this.activity.ActivityID;

    //show timer gauge dialog
    let dialogRef = this.dialog.open(VoteDialogComponent, {
      data: {
        currentStatus:this.activity.Status,
        activityID: activityID,
        status: ActivityStatus.Capture,
        title: 'Capture Ideas',
        description: 'Participants will be able to submit cards for each list.'
      },
      width: '500px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
     
      if(result){
        this.displayTimer = false;
        setTimeout(()=>{
          this.timerMaxValue = result.timerMaxValue;
          this.timerIsCountDown = result.timerIsCountDown;
          this.displayTimer = true;
          this.activity.Status = 'CAPTURE';
          this.ShowActivitySession(true);
        }, 600)        

      }

    });

  }
  
  CaptureResults(){
    var activityID = this.activity.ActivityID;
    this.dataService
        .GetActivityResult(this.dataStoreService.sessionData.sessionCode, activityID)
        .subscribe((data) => {
          this.activity = data;
          this.dataStoreService.sessionData.activity = this.activity;
          this.dataStoreService.Save();
          this.displayTimer = false;
          this.ShowActivitySession(true);
        });    
  }

  About(){
      let dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        content : 'SpikeOntIt! v.' + environment.appVersion,
        title: 'About',
        buttonText:'OK'
      },
      height:"500",
      disableClose: false,
    });

  }


  JoinAsParticipant(){
    let sessionCode = this.activitySession.SessionCode;
    let sessionUrl = "https://" + window.location.hostname + "?join=" + sessionCode;
    window.open(sessionUrl, "_blank", "", true);
  }

  ShowSessionAccess() {
    let dialogRef = this.dialog.open(SessioninfoComponent, {
      data: {
        sessionCode: this.dataStoreService.sessionData.sessionCode,
      },
      disableClose: true,
    });
  }

  Signout() {
    Auth.signOut();
  }
  
  updateSessionDescription() {

    this.dataService.UpdateActivitySession(this.activitySession).subscribe(
      (data) => {
        this.dataStoreService.Save();
        return;
      },
      (error) => {
        console.log('error updating activity session : ' + JSON.stringify(error));
        return;
      }
    );  
  
  }  
  
  updateActivityDescription() {

    this.dataService.UpdateActivity(this.activity).subscribe(
      (data) => {
        this.dataStoreService.Save();
        return;
      },
      (error) => {
        console.log('error updating activity  : ' + JSON.stringify(error));
        return;
      }
    );  
  
  }    

  NewActivity() {
    //show timer gauge dialog
    let dialogRef = this.dialog.open(NewActivityDialogComponent, {
      data: {},
      height: '150px',
      width: '500px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('newactc ' + result);
      if (result) {
        //complete the active activity
        this.dataService
          .UpdateActivityStatus(
            this.activity.ActivityID,
            ActivityStatus.Completed,
            5, false
          )
          .subscribe(
            (data) => {
              //navigate to new activity page
              this.router.navigate(['/', 'workspace', 'activity']);
            },
            (error) => {
              console.log('Error new activity ' + error);
            }
          );
      }
    });
  }
  

  fullscreenmodes(event) {
    this.chkScreenMode();
  }
  
  chkScreenMode() {
    if (document.fullscreenElement) {
      //fullscreen
      this.isFullScreen = true;
    } else {
      //not in full screen
      this.isFullScreen = false;
    }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
    this.isFullScreen = true;
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
    this.isFullScreen = false;
  }
}
