import { Component, OnInit, Input } from '@angular/core';
import { DatastoreService } from '../../../../../core/services/datastore.service';
import { DataService } from '../../../../../core/services/data.service';
import { Router } from '@angular/router';
import { Activity } from '../../../../../core/models/activitytemplate.model'


@Component({
  selector: 'app-onebox-main',
  templateUrl: './onebox-main.component.html',
  styleUrls: ['./onebox-main.component.scss']
})
export class OneboxMainComponent implements OnInit {
  @Input() activity :Activity;
  @Input() participantMode:boolean;
  constructor(private dataStoreService : DatastoreService, private dataService : DataService, private router : Router) { }

  
  ngOnInit(): void {
    //console.log("activity  " + JSON.stringify( this.dataStoreService.sessionData.activity));
    //this.activity = this.dataStoreService.sessionData.activity;
  }

  updateActivity(){
    this.dataService.UpdateActivity(this.activity).subscribe((data) => {
        this.dataStoreService.sessionData.activity = this.activity;
    },
    (error) => {
      console.log('error updating activity : ' + JSON.stringify(error));
    });
  }

}
