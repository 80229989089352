import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceSharedService {

  designMode : boolean;

  constructor(){
    
  }
  
 
  
  Log(description:string){
    console.log(description);
  }
  
  
 
  
}
