<h2 mat-dialog-title><mat-icon style="padding-right:10px;" >share</mat-icon>Share Retro Access</h2>

		<div>
		
			<ul>
				<li>
					<div [ngClass]="'links'">
						<h3>Use the following link <span id="urlLink">{{hostName}}?join={{sessionCode}}</span></h3>
						<button mat-icon-button (click)="CopyLink('urlLink')"><mat-icon>content_copy</mat-icon></button>
					</div>
				</li>
				<li>
					<div [ngClass]="'links'">
						<h3>Go to {{hostName}} and use session code <span id="sessionCodeLink">{{sessionCode}}</span></h3>
						<button mat-icon-button (click)="CopyLink('sessionCodeLink')"><mat-icon>content_copy</mat-icon></button>
					</div>
				</li>
			</ul>

		</div>

		<div *ngIf="activity == null">
			<h2 mat-dialog-title><mat-icon style="padding-right:10px;" >local_activity</mat-icon>Select Activity Template</h2>
			<ul>
				<li>
					<div [ngClass]="'links'">
						<h3>Pick an activity template to start your retro activity.</h3>
						<button style="margin-left:15px;" mat-raised-button color="primary" (click)="SelectActivityTemplate()">Select Activity Template</button>
					</div>
				</li>
			</ul>
			
		</div>


<mat-dialog-actions *ngIf="activity !== null" > 
	<button mat-raised-button color="primary" (click)="Close()">Close</button>
</mat-dialog-actions> 