<h2 mat-dialog-title>Card Settings</h2>
<div style="display:flex" class="box-vertical-left">
    <div>Background Image URL  <input [(ngModel)]="data.backgroundUrl" style="margin-left:5px;width:300px" type="text" name="backgroundImage"/></div>
    <div class="optionsSpacer">Background Color 
        <select name="backgrondColorlist" [(ngModel)]="selectedBackgroundColor" style="width:100px;background-color:{{selectedBackgroundColor}}">
            <option *ngFor="let color of colors" [value]='color' style="background-color:{{color}};"></option>
        </select>
    </div>
    <div class="optionsSpacer">Font Color 
        <select name="fontColorlist" [(ngModel)]="selectedFontColor" style="width:100px;background-color:{{selectedFontColor}}">
            <option *ngFor="let color of colors" [value]='color' style="background-color:{{color}};"></option>
        </select>
    </div>  
    <div class="optionsSpacer">Font Size 
        <select name="fontSizelist" [(ngModel)]="selectedFontSize" style="width:100px;">
            <option *ngFor="let font of fontSize" [value]='font.value' >{{font.display}}</option>
        </select>
    </div>      
</div>

<mat-dialog-actions>
    <button mat-raised-button color="basic" (click)="Close()">Cancel</button>
    <button mat-raised-button color="primary" (click)="SaveSettings()">OK</button>
</mat-dialog-actions>