import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import {DatastoreService} from '../../../core/services/datastore.service';
import {DataService} from '../../../core/services/data.service';
import {Router,ActivatedRoute} from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-landing-section',
  templateUrl: './landing-section.component.html',
  styleUrls: ['./landing-section.component.scss']
})
export class LandingSectionComponent implements OnInit {
  
  sessionCode : string = "";
  isSessionCodeInValid : boolean = false;
  isSessionCodeRequired : boolean = false;
  isLoggedIn:boolean = false;
  isLoaded:boolean = false;
  
  constructor(private authService: AuthService, private router: Router,private dataService: DataService, private dataStoreService: DatastoreService, private route: ActivatedRoute) { 
    this.dataStoreService.sessionData.newSession = false;
    this.dataStoreService.Save();

  }


  ngOnInit(): void {
     
    this.authService.isLoggedIn().then(value=> this.isLoggedIn = value);

    this.route.queryParams.pipe(
        filter((params: any)=> {
          return params.join
          })
      ).subscribe(x=> {
      this.sessionCode = x.join; 
      this.JoinSession();
    });
    
    if(this.sessionCode.length == 0){
      this.isLoaded = true;
    }

  }

  public JoinSession(){
        
    this.isSessionCodeInValid = false;
    this.isSessionCodeRequired = false;
    
    if(this.sessionCode.length == 0){
      this.isSessionCodeRequired = true;
      this.isLoaded = true;
      return;
    }

    this.dataService.GetActivitySession(this.sessionCode).subscribe(data=>{
      if(data == null){
        this.isSessionCodeInValid = true;
        this.isLoaded = true;
        return;
      }

      this.dataStoreService.sessionData.sessionCode = this.sessionCode;
      this.dataStoreService.Save();

      this.dataService.GetActivityBySessionCode(this.sessionCode).subscribe(data=>{
        if(data !== null){
            this.dataStoreService.sessionData.sessionCode = this.sessionCode;
            this.dataStoreService.sessionData.activity = data;
            this.dataStoreService.AddParticipantID(this.sessionCode, data.RequestID);
            this.dataStoreService.Save();
          }
      this.router.navigate(['/','workspace','session']);
        
      }, error=>{
        console.log("Error joining session : " + JSON.stringify(error));
      });
   
      
    });


  }


  public CreateNewSession(){
    this.dataStoreService.sessionData.newSession = true;
    this.dataStoreService.Save();
    this.router.navigate(['/','workspace','activity']);
  }

 
}
