


export class UrlConstants {
    static readonly baseApiUrl = 'https://6n3nyjsit7.execute-api.us-west-1.amazonaws.com/prod';
    static readonly CreateNewSession = UrlConstants.baseApiUrl + "/session";
    static readonly CreateNewActivity = UrlConstants.baseApiUrl + "/activity";
    static readonly UpdateActivity = UrlConstants.baseApiUrl + "/activity"; 
    static readonly GetActivityTemplate = UrlConstants.baseApiUrl + "/activitytemplate";
    static readonly UpdateActivityStatus = UrlConstants.baseApiUrl + "/activity/status";
    static readonly GetActivityBySessionCode = UrlConstants.baseApiUrl + "/activity/session";
    static readonly AddActivityResult = UrlConstants.baseApiUrl + "/activityresult";
    static readonly DeleteActivityResult = UrlConstants.baseApiUrl + "/activityresult";
    static readonly GetActivityResult = UrlConstants.baseApiUrl + "/activityresult";
    static readonly GetActivitySessionBySessionCode = UrlConstants.baseApiUrl + "/activitysession"
    static readonly UpdateActivitySession = UrlConstants.baseApiUrl + "/activitysession"
    
}