
<mat-card *ngIf="loadedData" [ngStyle]="border" class="card card-node mat-elevation-z4 mat-card-padding" draggable="{{allowDragDrop}}" (dragstart)="dragStart($event)" id="{{data.UID}}">
    <mat-card-content>
         <div class="flex-container">
            <div tabIndex=0 name={{data.UID}} (keydown)="onCardDescriptionKeydown($event)" (dragover)="allowDrop($event)" *ngIf="activity.Status !== 'VOTE'"  class="drag-drop-target flex-child card-inner col-text description-spacer card-description" (blur)="updateItem()" (click)="enterItemDescriptionEditbox(data.UID, data.Description)"   (ngModelChange)="onItemDescriptionChange($event)" placeholder="Type description here" text-editable [(ngModel)]="data.Description">{{data.Description}}</div>
            <div (dragover)="allowDrop($event)" *ngIf="activity.Status == 'VOTE'"  class="drag-drop-target flex-child card-inner col-text description-spacer card-description">{{data.Description}}</div>
           
            <!--<div  *ngIf="activity.Status !== 'VOTE' && activity.Status !== 'CAPTURE'"  class="flex-child-actions">-->
            <div *ngIf="activity.Status == 'CAPTURE' || activity.Status !== 'VOTE'" class="flex-child-actions">
                <mat-menu #appMenu="matMenu">
                     <button mat-menu-item (click)="deleteItem(data.UID)">Remove Card</button>
                </mat-menu>
                <button  mat-icon-button [matMenuTriggerFor]="appMenu"><mat-icon class="material-icons color_blue">more_vert</mat-icon></button>
            </div>
        </div>
        <div *ngIf="groupedCardIsDisplayed" >
            <div *ngIf="activity.Status == 'PAUSED'"  >
                <div class="mat-elevation-z1" *ngFor="let item of data.Group">
                    <button (click)="removeFromCardGroup(item.UID)" mat-icon-button><mat-icon>remove_circle_outline</mat-icon></button>{{item.Description}}
                </div>
            </div>
            <div *ngIf="activity.Status == 'VOTE'" style="margin-top:10px;margin-left:10px;" >
                <ul  *ngFor="let item of data.Group">
                    <li>{{item.Description}}</li>
                </ul>
            </div>
            
        </div>
    </mat-card-content>
    <mat-card-actions  *ngIf="(activity.Status === 'VOTE' && participantMode)" class="flex-container card-action-display">
        <button mat-icon-button (click)="resetVote()"><mat-icon>restart_alt</mat-icon></button>
        <button class="vote-icon-offset-left" mat-icon-button color="primary" (click)="increaseVote()"><mat-icon>thumb_up_off_alt</mat-icon></button>
        <div class="vote-display">{{data.Vote}}</div>
        <div *ngIf="data.Group.length > 0" class="flex-container card-action-buttons">
             <button  mat-icon-button color="primary" matTooltip="Click to show or hide the grouped cards." mat-icon-button (click)="ShowOrHideGroupedCards()"><mat-icon>view_module</mat-icon></button>
        </div>

    </mat-card-actions>
    
    <mat-card-actions *ngIf="(activity.LastAction === 'VOTE' && activity.Status === 'PAUSED') || (activity.Status.toUpperCase() === 'PAUSED' && data.Group.length > 0)" class="flex-container card-action-display">
        <div *ngIf="activity.LastAction === 'VOTE' && activity.Status === 'PAUSED'" class="flex-container card-action-align">
            <button mat-icon-button color="primary"><mat-icon>thumb_up_off_alt</mat-icon></button>
            <div class="vote-display">{{data.Vote}}</div>
        </div>
        <div *ngIf="activity.Status.toUpperCase() === 'PAUSED' && data.Group.length > 0"  class="flex-container card-action-buttons">
             <button mat-icon-button color="primary" matTooltip="Click to show or hide the grouped cards." mat-icon-button (click)="ShowOrHideGroupedCards()"><mat-icon>view_module</mat-icon></button>
        </div>
    </mat-card-actions>

    
</mat-card>



