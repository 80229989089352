<amplify-authenticator initial-auth-state='signup' *ngIf="authState !== 'signedin' && authAction==='signup'">
   <amplify-sign-up
    slot="sign-up"
    usernameAlias="email"
    [formFields]="formFields">
  </amplify-sign-up>
</amplify-authenticator>

<amplify-authenticator initial-auth-state='signin' *ngIf="authState !== 'signedin' && authAction==='signin'">
   <amplify-sign-up
    slot="sign-up"
    usernameAlias="email"
    [formFields]="formFields">
  </amplify-sign-up>  
  <amplify-sign-in slot="sign-in" usernameAlias="email"></amplify-sign-in>
</amplify-authenticator>


<div *ngIf="authState === 'signedin' && user" class="App">
    <amplify-sign-out></amplify-sign-out>
    <div>Hello, {{user.username}}</div>
    <!-- This is where you application template code goes -->  
    
</div>

