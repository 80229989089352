import { Component, OnInit,Inject } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'



@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  
  constructor(public dialogRef:MatDialogRef<InfoDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
  }
  

  Close(){
      this.dialogRef.close();
  }

 
}
