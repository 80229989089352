<div class="nav-container">

  <mat-toolbar color="primary" class="mat-elevation-z1">
      <mat-toolbar-row>

        <div class="nav-menu-container"><button class="nav-menu" mat-icon-button><mat-icon style="color:white;display: flex;align-items: center;">offline_bolt</mat-icon></button></div>
    		<div class="nav-app-name">SpikeOnIt</div>

     </mat-toolbar-row>
  </mat-toolbar>
  
  <mat-sidenav-container class="nav-sidenav-container">
    <mat-sidenav-content>  


      <div class="main-header header-alignment ">Pick An Activity Template</div>
      <div class="activity-wrapper box-left-flex">
          <mat-card class="card mat-elevation-z6" *ngFor="let item of activityTemplate; let i = index">
            <mat-card-header>
              <div mat-card-avatar><mat-icon class=".spike-avatar">bolt</mat-icon></div>
              <mat-card-title>{{item.TemplateName}}</mat-card-title>
              <mat-card-subtitle>{{item.Category}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
      
              <p>
                {{item.Description}}
              </p>
            </mat-card-content>
            <mat-card-actions>
              <button *ngIf="item.TemplateID == 'X'" mat-raised-button (click)="GotoWorkshop($event,item)">Back To Board</button>
              <button *ngIf="item.TemplateID == '0'" mat-raised-button (click)="GotoWorkshop($event,item)">Select Later</button>
              <button *ngIf="item.TemplateID !== '0' && item.TemplateID !== 'X'" mat-raised-button (click)="GotoWorkshop($event,item)">Use Template</button>
            </mat-card-actions>
          </mat-card>
      </div>


	    </mat-sidenav-content>
  </mat-sidenav-container>
   
  
</div>