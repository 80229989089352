
<div class="align-header-items" *ngIf="data.status == 'VOTE'">
    <mat-icon>thumb_up_off_alt</mat-icon>
    <h2 mat-dialog-title>{{data.title}}</h2>
</div>

<h2 *ngIf="data.status == 'CAPTURE'" mat-dialog-title><mat-icon>lightbulb</mat-icon>{{data.title}}</h2>
<div *ngIf="data.currentStatus == 'VOTE' || data.currentStatus == 'CAPTURE'" style="color:red">Activity is already in {{data.currentStatus}} status.  Existing feedback submissions will be reset.</div>
<mat-dialog-content>
<div *ngIf="!started"  id="options-display">
        <h3>
            {{data.description}}
        </h3>

    <form *ngIf="!started" [formGroup]="options">
       <div>
            <div class="box-vertical-left options">
                <div *ngIf="data.status == 'VOTE'">
                    Set vote count limit to<input matInput type="number" min=1 max=99 formControlName="maxVoteCount" class="timerMinute" maxlength="2" step="1"> votes.
                    <div *ngIf="timer.errors">
                        Vote count range is between 1 and 99.
                    </div>
                    <div *ngIf="listCount > 1" style="margin-left:20px">
                        <mat-checkbox formControlName="maxVotePerColumn">Apply max vote count per column.</mat-checkbox>
                    </div>
                </div>
            
                
                <div class="optionsSpacer">
                    Set time limit to <input matInput type="number" min=1 max=60 formControlName="timer" class="timerMinute" maxlength="2" step="1"> minute(s).
                    <div *ngIf="timer.errors">
                        Timer duration between 1 and 60.
                    </div>
                    <div style="margin-left:20px">
                         <mat-checkbox formControlName="countDown">Set timer to countdown</mat-checkbox>
                    </div>
                </div>
               
   
            </div>
        </div>
    </form>

</div>
<div *ngIf="started"  id="timer-display">
    <div>
        <timer-watch [maxMinutes]="timer.value" [isCountdown]="countDown.value"></timer-watch>
    </div>
</div>
</mat-dialog-content>

<mat-dialog-actions>
    <div *ngIf="!started">
        <button mat-raised-button color="basic" (click)="Close()">Cancel</button>
        <button id="start" mat-raised-button color="primary" (click)="Go()">Start</button>
    </div>      
    
    <div *ngIf="started">
        <button mat-raised-button color="primary" (click)="Close()">Done</button>
    </div>    
</mat-dialog-actions>

