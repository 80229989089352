

<div id="onelistmain" class="container-fullscreen">
    <div id="onelist" class="lists">
        <app-list class="list-container" *ngFor="let list of activity.Template.Lists" [group]="list" [participantMode]=participantMode  [removeListFunction]="removeList"></app-list>
        <div *ngIf="activity.Status !== 'VOTE' && activity.Status !== 'CAPTURE'" class="add-list-button">
            <button id="addNewList" mat-raised-button color="primary" aria-label="Add New List" (click)="addNewList()"><mat-icon>add_circle_outline</mat-icon> Add New List</button>
        </div>
    </div>
</div>
  



