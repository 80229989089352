import { Injectable } from '@angular/core';
import { Observable , of} from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

import { Auth } from 'aws-amplify';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
const apiUrl = 'http://localhost:3000/api/v1/products';  
  

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient) { }
  

  private tokenKey:string = 'token';
  private tokenRefreshKey:string = 'refreshToken';
  
  public storeToken(content:Object) {
      localStorage.setItem(this.tokenKey, JSON.stringify(content));
  }
  
  public storeRefreshToken(content:Object) {
      localStorage.setItem(this.tokenRefreshKey, JSON.stringify(content));
  }  


  private retrieveRefreshToken() {
      let storedToken:string | null = localStorage.getItem(this.tokenRefreshKey);
      return storedToken;
  }
  


  public retrieveToken() {

    try {
      let storedToken:string | null = localStorage.getItem(this.tokenKey);
      return storedToken;
    }
    catch(err) {
        console.error(err);
        return '';
    }
      
  }
  
  private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to  logging 
    console.error(error); 

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

}
