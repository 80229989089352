import { Injectable } from '@angular/core';
import { SessionData, LocalData, ParticipantData } from '../../core/models/activitytemplate.model'

@Injectable({
  providedIn: 'root',
})
export class DatastoreService   {
 
  sessionData : SessionData ; 
  localData : LocalData;
  
  sessionStorage : Storage;
  localStorage : Storage;
  
  constructor(){
    this.sessionStorage = window.sessionStorage;
    this.localStorage = window.localStorage;
  
     if(typeof this.sessionData === 'undefined' || this.sessionData === null){
      this.sessionData = JSON.parse(this.sessionStorage.getItem("sessionData"));
    }
    
    if(typeof this.sessionData === 'undefined'  || this.sessionData === null){
      this.sessionData = new SessionData();
    }
    
    if(typeof this.localData === 'undefined'  || this.localData === null){
      this.localData = JSON.parse(this.sessionStorage.getItem("localData"));
    }   
    
    if(typeof this.localData === 'undefined'  || this.localData === null){
      this.localData = new LocalData();
    }
    
  };
  
  AddParticipantID(sessionCode:string, participantID:string){
    var item = this.localData.SessionParticipantData.filter(item=> item.SessionCode === sessionCode);
    if(item.length === 0){
      let data = new ParticipantData(sessionCode, participantID);
      this.localData.SessionParticipantData.push(data);
    }else{
      //console.log("found a match for session code " + JSON.stringify(this.localStorage));
    }
  }

  GetParticipantID(sessionCode:string){
    var item = this.localData.SessionParticipantData.filter(item=> item.SessionCode === sessionCode);
    if(item.length > 0){
      var participantId = item[0].ParticipantID;
      return participantId;
    }else {
      return "";
    }
    
  }


  Save(){
    this.sessionStorage.setItem("sessionData", JSON.stringify(this.sessionData));
    this.sessionStorage.setItem("localData", JSON.stringify(this.localData));
  }
  
}
  
 





