


export class ActivityStatus {
    static readonly Paused = 'PAUSED';
    static readonly Vote = 'VOTE';
    static readonly Capture = 'CAPTURE';
    static readonly Deleted = 'DELETED';
    static readonly Completed = 'COMPLETED';
    static readonly Contributed = 'CONTRIBUTED';
}