import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/services/data.service'
import { Router } from '@angular/router';
import { DatastoreService } from '../../../core/services/datastore.service';


@Component({
  selector: 'app-new-session',
  templateUrl: './new-session.component.html',
  styleUrls: ['./new-session.component.scss']
})

export class NewSessionComponent implements OnInit {

  description : string = "";
  showDescriptionRequiredWarning: boolean = false;
  constructor(private dataService: DataService, private router:Router, private dataStoreService : DatastoreService) { 
    
  }

  ngOnInit(): void {
    
  }
  
  CreateNewSession(){
    console.log("my session description is " + this.description);
    
    if(this.description.length == 0){
      this.showDescriptionRequiredWarning = true;
      return;
    }
    
    this.dataService.CreateNewSession(this.description).subscribe(data => {
      console.log(JSON.stringify(data));
      this.dataStoreService.sessionData.sessionCode = data.Code;
      this.dataStoreService.sessionData.newSession = true;
      console.log("the new code is : " + this.dataStoreService.sessionData.sessionCode);
      
      this.router.navigate(['/','workspace','activity']);
      
      
    }, (error)=>{
      console.log("Error detected while creating a new session.");
      console.log(JSON.stringify(error));
    });
    
  }

}
